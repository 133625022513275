<template>
    <img class="image-cell" v-if="data.url" :src="data.url" :alt="data.alt" >
</template>

<script>
export default {
    name: "ImageCell",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
}
</script>

<style scoped>
.image-cell{
    max-width: 100%;
}

</style>